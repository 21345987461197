import './styles.css';
import { Router } from '@vaadin/router';

// developer
// <script src="https://www.paypal.com/sdk/js?client-id=AWtX5rDwk2pdWLMSdXR7hc4SdDgEZ-8n59jiiXPLDoWmG38v40XXjOgtBUcnuLURpUetUACufj0X3yZ3&currency=USD" data-sdk-integration-source="button-factory"></script>

// real
// <script src="https://www.paypal.com/sdk/js?client-id=AWvqXXISfhSOgwoOt2bmIy6iZoxLbPd7bEiiwEZQWNRPHd27QyU4vigtIT7nLICXLGij8bpOzfpbOkPv&currency=USD" data-sdk-integration-source="button-factory"></script>


var domain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
if(!domain){
    domain = 'luvata';
}
var zcontent = {domain: domain};
// console.log("zcontent", zcontent);

 //sessionStorage.setItem('domain', domain);

if (sessionStorage.getItem("mp-order") === null) {
  var mporder = {domain: domain};
  mporder.cart = [];
  sessionStorage.setItem("mp-order", JSON.stringify(mporder));
}

 window.addEventListener('load', () => {
    
  (async () => {
    const rawResponse = await fetch('https://storeapi.zmailpro1.com/domains/read.php', {
    method: 'POST',
    headers: {
//            'Content-Type': 'application/json'
    },
    body: JSON.stringify(zcontent)
    });
    var domaininfo = await rawResponse.json();
////     console.log("this.domain", domaininfo[0]);
    sessionStorage.setItem("mp-domain", JSON.stringify(domaininfo));

    initRouter();

  })();  

});

  function initRouter() {
  //  console.log("In initRouter");
    const router = new Router(document.querySelector('main'));
    router.setRoutes([
      {
        path: '/',
        component: 'home-view',
        action: () => import('./views/home-view.js')
      },
      {
        path: '/home',
        component: 'home-view',
        action: () => import('./views/home-view.js')
      },
      {
        path: '/register',
        component: 'register-view',
        action: () => import('./views/register-view.js')
      },
      {
        path: '/registration-confirmation/:zid',
        component: 'register-confirmation-view',
        action: () => import('./views/register-confirmation-view.js')
      },
      {
        path: '/login',
        component: 'login-view',
        action: () => import('./views/login-view.js')
      },
      {
        path: '/thank-you-for-registering',
        component: 'register-thankyou-view',
        action: () => import('./views/register-thankyou-view.js')
      },
      {
        path: '/products/:zid',
        component: 'products-view',
        action: () => import('./views/products-view.js')
      },
      {
        path: '/categories/:zid',
        component: 'categories-view',
        action: () => import('./views/categories-view.js')
      },
      {
        path: '/product/:zid',
        component: 'product-view',
        action: () => import('./views/product-view.js')
      },
      {
        path: '/shipment/:shipmentid',
        component: 'shipment-view',
        action: () => import('./views/manage/shipment-view.js')
      },
      {
        path: '/vieworder/:zid',
        component: 'order-view',
        action: () => import('./views/order-view.js')
      },
      {
        path: '/vieworder2/:zid',
        component: 'order-view2',
        action: () => import('./views/order-view2.js')
      },
      {
        path: '/resetpassword/:zid',
        component: 'password-reset-view',
        action: () => import('./views/password-reset-view.js')
      },
      {
        path: '/shipping',
        component: 'checkout-shipping-view',
        action: () => import('./views/checkout/checkout-shipping-view.js')
      },
      {
        path: '/payment',
        component: 'checkout-payment-view',
        action: () => import('./views/checkout/checkout-payment-view.js')
      },
      {
        path: '/payment2',
        component: 'checkout-payment-test-view',
        action: () => import('./views/checkout/checkout-payment-test-view.js')
      },
      {
        path: '/account',
        component: 'account-view',
        action: () => import('./views/account-view.js')
      },
      {
        path: '/addressbook',
        component: 'address-book-view',
        action: () => import('./views/address-book-view.js')
      },
      {
        path: '/profile',
        component: 'profile-view',
        action: () => import('./views/profile-view.js')
      },
      {
        path: '/checkout',
        component: 'checkout-view',
        action: () => import('./views/checkout/checkout-view.js')
      },
      {
        path: '/checkout-delcocc',
        component: 'checkout-delcocc-view',
        action: () => import('./views/checkout/checkout-delcocc-view.js')
      },
      {
        path: '/checkout-luvata',
        component: 'checkout-luvata-view',
        action: () => import('./views/checkout/checkout-luvata-view.js')
      },
      {
        path: '/checkout-guest',
        component: 'checkout-guest-view',
        action: () => import('./views/checkout/checkout-guest-view.js')
      },
      {
        path: '/checkout-summary',
        component: 'checkout-summary-view',
        action: () => import('./views/checkout/checkout-summary-view.js')
      },
      {
        path: '/contact',
        component: 'contact-view',
        action: () => import('./views/contact-view.js')
      },    
      {
        path: '/users',
        component: 'users-view',
        action: () => import('./views/manage/users-view.js')
      },    
      {
        path: '/emails',
        component: 'emails-view',
        action: () => import('./views/manage/emails-view.js')
      }, 
      {
        path: '/manage-products',
        component: 'manage-products-view',
        action: () => import('./views/manage/manage-products-view.js')
      },
      {
        path: '/manage-product/:zid',
        component: 'manage-product-view',
        action: () => import('./views/manage/manage-product-view.js')
      }, 
      {
        path: '/manage-commission',
        component: 'manage-commission-view',
        action: () => import('./views/manage/manage-commission-view.js')
      },   
      {
        path: '/manage-orders',
        component: 'orders-manage-view',
        action: () => import('./views/manage/manage-orders-view.js')
      },
      {
        path: '/manage-domain',
        component: 'manage-domain-view',
        action: () => import('./views/manage/manage-domain-view.js')
      },
      {
        path: '/manage-category',
        component: 'manage-category-view',
        action: () => import('./views/manage/manage-category-view.js')
      },
      {
        path: '/success',
        component: 'success-view',
        action: () => import('./views/success-view.js')
      },
      {
        path: '/success-hibaby',
        component: 'success-hibaby-view',
        action: () => import('./views/success-hibaby-view.js')
      },
      {
        path: '/cart',
        component: 'cart-view',
        action: () => import('./views/checkout/checkout-cart-view.js')
      },
      {
        path: '(.*)',
        component: 'not-found-view',
        action: () => import('./views/not-found-view.js')
      }
      
    ]);
  }
    
